.App {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #000000;
}

.file-list {
  width: 200px;
  background: #333842;
  color: #ffffff;
  padding: 10px;
}

.file-list-item {
  padding: 10px;
  cursor: pointer;
}

.file-list-item.selected {
  background: #555a64;
}

.file-list button {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4caf50;
  border: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.CodeEditor {
  border-radius: 10px;
}

.CodeEditor .monaco-editor {
  background-color: #1e2027;
  color: #ffffff;
  border-radius: 10px;
}

.CodeEditor .editor-toolbar {
  background-color: #333842;
}

.CodeEditor .monaco-editor-background,
.CodeEditor .monaco-editor .margin,
.CodeEditor .monaco-editor .minimap-shadow,
.CodeEditor .monaco-editor .minimap-slider,
.CodeEditor .monaco-editor .scrollbar,
.CodeEditor .monaco-editor .scrollbar-thumb,
.CodeEditor .monaco-editor .scrollbar .slider,
.CodeEditor .monaco-editor .scrollbar.vertical,
.CodeEditor .monaco-editor .scrollbar.horizontal {
  background-color: #1e2027;
}

.CodeEditor .monaco-editor .margin,
.CodeEditor .monaco-editor .minimap-shadow,
.CodeEditor .monaco-editor .scrollbar,
.CodeEditor .monaco-editor .scrollbar.vertical {
  border-color: #1e2027;
}

.CodeEditor .monaco-editor .scrollbar-thumb,
.CodeEditor .monaco-editor .scrollbar .slider {
  background-color: #333842;
  border-color: #333842;
}
.blocklyTreeLabel {
  color: black !important;
}
